import { Cog6ToothIcon } from '@heroicons/react/24/outline'
import NavLinks from '../NavLinks/Index'
import SubNavLinks from '../SubNavLinks/Index'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

const Navbar = ({ isSeller, isAdmin, setIsAdmin }) => {
  const { user } = useSelector((state) => state.auth)

  return (
    <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
      <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white dark:bg-gray-950 px-6 pb-4">
        <div className="flex h-16 shrink-0 items-center">
          {isSeller ? (
            <Link to={`/seller/${user?.username}`}>
              <span className="text-2xl py-2 font-bold text-rose-500">
                CRM Gurus
              </span>
            </Link>
          ) : isAdmin ? (
            <Link to={`/admin/`}>
              <span className="text-2xl py-2 font-bold text-rose-500">
                CRM Gurus
              </span>
            </Link>
          ) : (
            <Link to={`/buyer/${user?.username}`}>
              <span className="text-2xl py-2 font-bold text-rose-500">
                CRM Gurus
              </span>
            </Link>
          )}
        </div>
        <nav className="flex flex-1 flex-col">
          <ul className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul className="-mx-2 space-y-1">
                <NavLinks
                  isSeller={isSeller}
                  isAdmin={isAdmin}
                  setIsAdmin={setIsAdmin}
                />
              </ul>
            </li>
            <li>
              <div className="text-xs font-semibold leading-6 text-gray-400">
                Your teams
              </div>
              <ul className="-mx-2 mt-2 space-y-1">
                <SubNavLinks />
              </ul>
            </li>
            <li className="mt-auto">
              <Link
                to="/buyer/settings"
                className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 dark:text-white text-gray-700 hover:bg-gray-50 dark:hover:bg-rose-900 hover:text-rose-600"
              >
                <Cog6ToothIcon
                  className="h-6 w-6 shrink-0 text-gray-400 dark:text-white dark:group-hover:text-white group-hover:text-rose-600"
                  aria-hidden="true"
                />
                Settings
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}
export default Navbar
