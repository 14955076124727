import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { startChat } from '../../../store/chatbot/chatBotService'

const ChatBot = () => {
  const messagesEndRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isTyping, setIsTyping] = useState(false)
  const [prompt, setPrompt] = useState('')
  // const [error, setError] = useState(null)
  const [firstMessage, setFirstMessage] = useState(true)
  const [messages, setMessages] = useState([])
  const { user } = useSelector((state) => state.auth)

  //   const handlePromptChange = async (e) => {
  //       setPrompt(e.target.value)
  //   }

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  useEffect(() => {
    setMessages([
      {
        text: `Hi there 👋 <br /> ${user.first_name}`,
        type: 'incoming',
      },
      {
        text: `Welcome to CRM Gurus`,
        type: 'incoming',
      },
      {
        text: `How can I assist you?`,
        type: 'incoming',
      },
    ])
  }, [])

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      setIsOpen(!isOpen)
    }
  }

  const handleCloseButton = () => {
    setIsOpen(!isOpen)
  }

  const handleFormSubmit = async () => {
    try {
      setIsTyping(true)

      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: prompt,
          type: 'outgoing',
        },
      ])

      const response = await startChat({ input: prompt, firstMessage })

      setPrompt('')
      setFirstMessage(false)
      setIsTyping(false)

      const data = await response.data

      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: data.response.response,
          type: data.type,
        },
      ])
    } catch (error) {
      prompt && setPrompt('')
      console.log(error.message)
    }
  }

  return (
    <div className={isOpen ? 'show-chatbot' : ''}>
      <div
        role="button"
        tabIndex={0}
        onClick={() => handleCloseButton()}
        onKeyDown={handleKeyDown}
        className="chatbot-toggler"
      >
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 011.037-.443 48.282 48.282 0 005.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
            />
          </svg>
        </span>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </span>
      </div>
      <div className="chatbot">
        <header>
          <h2 className="text-5xl text-green-500">💬 CRM Bot</h2>
          <span
            role="button"
            tabIndex={0}
            onKeyDown={handleKeyDown}
            className={isOpen ? 'show-chatbot' : ''}
            onClick={() => handleCloseButton()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
        </header>
        <ul className="chatbox">
          {isTyping && (
            <li className="chat incoming">
              <p>Thinking...</p>
            </li>
          )}
          {messages &&
            messages.map((message, index) => (
              <div key={index}>
                <li className={`chat ${message.type}`}>
                  <p dangerouslySetInnerHTML={{ __html: message.text }} />
                </li>
                <div ref={messagesEndRef} />
              </div>
            ))}
        </ul>
        <div className="chat-input">
          <textarea
            rows={1}
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            onKeyDown={(e) =>
              e.key === 'Enter' && handleFormSubmit() && setPrompt('')
            }
            placeholder={'Enter your Message'}
            required
          />
          <span
            onClick={handleFormSubmit}
            role="button"
            tabIndex={0}
            onKeyDown={(e) =>
              e.key === 'Enter' && handleFormSubmit() && setPrompt('')
            }
            id="send-btn"
            className="flex items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
              />
            </svg>
          </span>
        </div>
      </div>
    </div>
  )
}
export default ChatBot
