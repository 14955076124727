import { StylesConfig } from 'react-select'

export const techOptions = [
  'ActiveCampaign',
  'Adobe Illustrator',
  'Adobe Photoshop',
  'Adobe Xd',
  'Airtable',
  'Asana',
  'Atlassian',
  'Autopilot',
  'AWS',
  'BigCommerce',
  'Canva',
  'ChatBot',
  'ClickUp',
  'Django',
  'Facebook Ads',
  'Figma',
  'Google Ads',
  'Google Analytics',
  'Google Docs',
  'G Suite',
  'HubSpot',
  'Integromat',
  'Intercom',
  'Invision',
  'Java',
  'JavaScript',
  'Jira',
  'Klaviyo',
  'LinkedIn Ads',
  'LiveChat',
  'Magento',
  'MailChimp',
  'Microsoft Azure',
  'Monday',
  'MongoDB',
  'MS Dynamics',
  '.NET',
  'Node.js',
  'Notion',
  'MS Office',
  'Other',
  'PandaDoc',
  'PHP',
  'Pipedrive',
  'Power BI',
  'PrestaShop',
  'Python',
  'ReactJS',
  'React',
  'Remote Partner Network',
  'Salesforce',
  'SAP',
  'Sharetribe',
  'Shopify',
  'Sketch',
  'Slack',
  'SQL',
  'Stripe',
  'Tableau',
  'Twilio',
  'Typeform',
  'Webflow',
  'WooCommerce',
  'Woodpecker',
  'WordPress',
  'Xero',
  'Zapier',
  'Zendesk',
  'Zoho',
]

export const convertToReactSelectObject = (techOptions: Array<string>) => {
  return techOptions.map((x: string) => {
    return { value: x, label: x }
  })
}

export const colourStyles: StylesConfig = {
  input: (styles) => {
    return {
      ...styles,
      'input:focus': {
        boxShadow: 'none',
      },
      paddingTop: '.8rem',
      paddingBottom: '.8rem',
    }
  },
  control: (base, state) => ({
    ...base,
    boxShadow: !state.isFocused ? '0 !important' : ' 0px 0px 5px -1px #f43f5e',
    borderColor: !state.isFocused ? '#cccccc' : '#f43f5e',
    outline: !state.isFocused ? '#cccccc' : '1px solid #f43f5e',
    marginTop: '10px',
  }),
}
