import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import DOMPurify from 'dompurify'
import he from 'he'

const SidePanel = ({ isOpen, setIsOpen, project }) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 dark:bg-opacity-15 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-4xl">
                  <div className="flex h-full flex-col overflow-y-scroll dark:bg-gray-950 dark:border dark:border-white bg-white py-6 shadow-xl">
                    <div className="flex items-start justify-between">
                      <div className="px-4">
                        <Dialog.Title className="text-base font-semibold leading-6 dark:text-white text-gray-900">
                          Project Details
                        </Dialog.Title>
                      </div>
                      <div className="px-4">
                        <button
                          type="button"
                          className="relative rounded-md bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2"
                          onClick={() => setIsOpen(false)}
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon
                            className="h-6 w-6 bg-transparent"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </div>
                    <div className="py-4 px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-2xl font-semibold leading-6 dark:text-white text-gray-900">
                          {project.title}
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <Link
                            to={`/seller/projects/${project._id}/proposals/apply`}
                            type="button"
                            className="mr-2 rounded-md bg-rose-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600"
                          >
                            Apply Now
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      <div
                        className="mt-2 space-y-4 text-md dark:text-white text-gray-700"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            he.decode(project.description)
                          ), // Sanitize the HTML content
                        }}
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default SidePanel
