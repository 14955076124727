import { Link } from 'react-router-dom'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const teams = [
  { id: 1, name: 'Heroicons', href: '#', initial: 'H', current: false },
  { id: 2, name: 'Tailwind Labs', href: '#', initial: 'T', current: false },
]

const SubNavLinks = () => {
  return teams.map((team) => (
    <li key={team.name}>
      <Link
        to={team.href}
        className={classNames(
          team.current
            ? 'bg-gray-50 text-rose-600'
            : 'text-gray-700 dark:text-gray-900 hover:dark:text-rose-600 hover:text-rose-600 hover:bg-gray-50',
          'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
        )}
      >
        <span
          className={classNames(
            team.current
              ? 'text-rose-600 border-rose-600'
              : 'text-gray-400 dark:text-gray-900 hover:dark:text-rose-600 border-gray-200 group-hover:border-rose-600 group-hover:text-rose-600',
            'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white'
          )}
        >
          {team.initial}
        </span>
        <span className="truncate">{team.name}</span>
      </Link>
    </li>
  ))
}
export default SubNavLinks
