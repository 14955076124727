import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import TopBar from '../TopBar/Index'
import CookiePolicy from '../CookieConsent/Index'
import Banner from '../Banner/Index'
import MobNav from '../MobNav/Index'
import Navbar from '../Navbar/Index'
import Layout from '../Index'
import { useNavigate } from 'react-router-dom'

function SellerLayout({ children }) {
  const [isSeller, setIsSeller] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [cookie] = useCookies(['cookieConsent'])
  const { user } = JSON.parse(localStorage.getItem('persist:auth') || '{}')
  const navigate = useNavigate()

  useEffect(() => {
    if (!isSeller) return navigate(`/buyer/${user?.username}`)
  }, [isSeller])

  return (
    <>
      <Layout>
        {/* Mobile nav sidebar for mobile */}
        <MobNav
          isSeller={isSeller}
          setIsSeller={setIsSeller}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        {/* Static sidebar for desktop */}
        <Navbar isSeller={isSeller} setIsSeller={setIsSeller} />
        <div className="lg:pl-72">
          <TopBar
            isSeller={isSeller}
            setIsSeller={setIsSeller}
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
          />
          <main>{children}</main>
          {!cookie.cookieConsent && <CookiePolicy />}
          {isOpen ? <Banner isOpen={isOpen} setIsOpen={setIsOpen} /> : null}
        </div>
      </Layout>
    </>
  )
}

export default SellerLayout
