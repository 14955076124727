import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { io } from 'socket.io-client'
import { RootState } from '../../store'
import { disconnectSocket, setSocket } from '../../store/socket/socketReducer'

const URL =
  process.env.NODE_ENV === 'production'
    ? 'https://app.crmgurus.net'
    : 'http://localhost:3001'

const socket = io(URL, {
  cert: process.env.NODE_ENV === 'production' ? process.env.SSL_CERT : '',
  key: process.env.NODE_ENV === 'production' ? process.env.SSL_KEY : '',
  path: '/socket',
  reconnection: true,
  transports: ['websocket', 'polling'],
  reconnectionAttempts: 5,
})

function Layout({ children }) {
  const { isDark } = useSelector((state: RootState) => state.theme)
  const { user } = useSelector((state: RootState) => state.auth)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches

  useEffect(() => {
    socket.on('connect', () => {
      socket.emit('joined', 'Hello from React Client')
      // console.log(socket);
      dispatch(setSocket(socket.id))
    })

    socket.on('disconnect', () => {
      console.log('disconnected')
      dispatch(disconnectSocket(null))
    })

    socket.on('userLoggedIn', (data) => {
      console.log(data)
    })
  }, [])

  useEffect(() => {
    if (!(user as { isVerified?: boolean })?.isVerified) {
      navigate(`/auth/verify-email`)
    }

    if (systemTheme) {
      document.documentElement.classList.remove('dark')
    }

    if (isDark) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [isDark])
  return children
}

export default Layout
