import { useState } from 'react'
import { useCookies } from 'react-cookie'
import TopBar from '../TopBar/Index'
import CookiePolicy from '../CookieConsent/Index'
import MobNav from '../MobNav/Index'
import Navbar from '../Navbar/Index'
import ChatBot from '../ChatBot/Index'
import Layout from '../Index'

function BuyerLayout({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [cookie] = useCookies(['cookieConsent', 'announcementBanner'])

  return (
    <>
      <Layout>
        {/* Mobile nav sidebar for mobile */}
        <MobNav sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        {/* Static sidebar for desktop */}
        <Navbar />
        <div className="lg:pl-72">
          <TopBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <main>{children}</main>
          {!cookie.cookieConsent && <CookiePolicy />}
          {/* {!cookie.announcementBanner && !isOpen && <Banner setIsOpen={setIsOpen} />} */}
          <ChatBot />
        </div>
      </Layout>
    </>
  )
}

export default BuyerLayout
