import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import Select from 'react-select'
import {
  colourStyles,
  convertToReactSelectObject,
  techOptions,
} from './utlis/TechOptions'
import {
  createBuyerProject,
  setCurrentStep,
} from '../../store/buyerProject/buyerProjectReducer'
import { useNavigate } from 'react-router-dom'

const StepThree = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const re =
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm

  const formSchema = Yup.object().shape({
    url: Yup.string()
      .matches(re, 'URL is not valid')
      .required('URL is required'),

    skills: Yup.array().min(1).required('Project Skills Required'),
    phone: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(10, 'Must be at least 10 digits')
      .max(15, 'Must be at most 15 digits')
      .required('Phone number is required'),
  })

  const formik = useFormik({
    initialValues: {
      skills: [],
      url: '',
      phone: '',
    },
    validationSchema: formSchema,
    onSubmit: (values) => {
      //   console.log(values)
      dispatch(createBuyerProject(values))
      navigate('/buyer/projects')
    },
  })

  const handleBack = () => {
    dispatch(setCurrentStep(2))
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mt-5">
        <label
          htmlFor="location"
          className="block text-sm font-medium text-gray-700"
        >
          If you know what skills can be used, please select them below
        </label>
        <Select
          isMulti
          name="colors"
          options={convertToReactSelectObject(techOptions)}
          value={formik.values.skills.map((x: any) => ({
            value: x,
            label: x,
          }))}
          onChange={(value) => {
            formik.setFieldValue(
              'skills',
              value.map((x: any) => x.value)
            )
          }}
          styles={colourStyles}
          className="basic-multi-select"
          classNamePrefix="select"
          // menuIsOpen={true}
        />
        {formik.errors.skills && formik.touched.skills ? (
          <span className="text-red-500 text-sm">{formik.errors.skills}</span>
        ) : null}
      </div>

      <div className="mt-5">
        <label
          htmlFor="company-website"
          className="block text-sm font-medium text-gray-700"
        >
          Provide a link to details of your project or your website
        </label>
        <div className="mt-2 flex rounded-md shadow-sm">
          <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 dark:bg-gray-950 bg-gray-50 px-3 text-gray-500 sm:text-sm">
            https://
          </span>
          <input
            type="text"
            {...formik.getFieldProps('url')}
            id="company-website"
            className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 dark:bg-gray-950 dark:text-white px-3 py-4 focus:border-rose-500 focus:ring-rose-500 sm:text-sm"
            placeholder="www.example.com"
          />
        </div>
        {formik.errors.url && formik.touched.url ? (
          <span className="text-red-500 text-sm">{formik.errors.url}</span>
        ) : null}
      </div>

      <div className="mt-5">
        <label
          htmlFor="title"
          className="block text-sm font-medium text-gray-700 dark:text-white"
        >
          Phone Number
        </label>
        <div className="mt-2">
          <input
            type="tel"
            id="phone"
            {...formik.getFieldProps('phone')}
            className="block py-4 w-full rounded-md border-gray-300 dark:bg-gray-950 dark:text-white shadow-sm focus:border-rose-500 focus:ring-rose-500 sm:text-sm"
            placeholder="Phone Number"
          />
          {formik.errors.phone && formik.touched.phone ? (
            <span className="text-red-500 text-sm">{formik.errors.phone}</span>
          ) : null}
        </div>
      </div>

      <div className="mt-5">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={handleBack}
            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2"
          >
            Back
          </button>
          <button
            type="submit"
            disabled={!formik.dirty || formik.isSubmitting}
            className={
              formik.dirty
                ? 'ml-3 inline-flex justify-center rounded-md border border-transparent bg-rose-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2'
                : 'ml-3 inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2 disabled: cursor-not-allowed bg-gray-300'
            }
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  )
}
export default StepThree
