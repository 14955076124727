import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <main className="grid min-h-screen place-items-center bg-white dark:bg-gray-950 px-6 py-24 sm:py-32 lg:px-8">
      <div className="py-96 flex justify-center items-center">
        <div className="text-center">
          <p className="text-7xl font-medium text-rose-600">404</p>
          <h1 className="mt-4 text-7xl font-bold tracking-tight dark:text-white text-gray-900 sm:text-7xl">
            Page not found
          </h1>
          <p className="mt-6 text-base leading-7 dark:text-white text-gray-600">
            Sorry, we couldn’t find the page you’re looking for.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              to="/"
              className="rounded-md bg-rose-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600"
            >
              Go back home
            </Link>
            <a
              href="//crmgurus.net"
              className="text-sm font-semibold dark:text-white text-gray-900"
            >
              Contact support <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
      </div>
    </main>
  )
}

export default NotFound
