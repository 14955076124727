import { Link } from 'react-router-dom'

const Panel = (props) => {
  return (
    <div className="m-4 divide-y divide-gray-200 dark:border dark:border-gray-50 rounded-lg bg-white dark:bg-gray-950 shadow">
      <div className="flex justify-between items-center px-4 py-5 sm:px-6">
        <div>
          <h2 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
            {props.title}
          </h2>
          <p className="mt-1 text-sm text-gray-500 dark:text-white">
            {props.description}
          </p>
        </div>
        <div>
          {props.url && (
            <Link to={props?.url}>
              {props?.icon && (
                <props.icon className="h-10 w-10 text-rose-500" />
              )}
            </Link>
          )}
        </div>
      </div>
      <div className="px-4 py-5 sm:p-6">{props.children}</div>
    </div>
  )
}
export default Panel
